import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { DateTime } from 'luxon'

import { Title, Section, Text } from '../../components/Core'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import * as newsActions from '../../store/actions/newsActions'
import * as styles from './LatestNews.module.css'

const Feed = styled.span`
  color: #696969;
  font-size: 16px;
  font-weight: 300;
  margin-right: 5px;
  display: flex;
  align-items: center;
`

const LatestNews = () => {
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const { t, i18n, ready } = useTranslation( ['newsSection'] , { useSuspense: false });

  useEffect(() => {
    dispatch(newsActions.fetchLatestNews())
  }, [dispatch])

  const latestNews = useSelector(state => state.news.latestNews)
  var current = Date.now()

  const timeDifference = (current, previous) => {
    var msPerMinute = 60 * 1000
    var msPerHour = msPerMinute * 60
    var msPerDay = msPerHour * 24
    var msPerMonth = msPerDay * 30
    var msPerYear = msPerDay * 365

    var elapsed = current - previous

    if (elapsed < msPerMinute && ready) {
      return Math.round(elapsed / 1000) + t('seconds')
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + `${t('minutes')}`
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + t('hours')
    } else if (elapsed < msPerMonth && Math.round(elapsed / msPerDay) < 2) {
      return Math.round(elapsed / msPerDay) + t('days')
    } else if (elapsed < msPerMonth) {
      return previous.toString().slice(4, 15)
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + t('months')
    } else {
      return Math.round(elapsed / msPerYear) + t('years')
    }
  }

  return ready && (
    <>
      <Section bg={userDarkMode ? 'black' : 'bg'}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Title
                color={userDarkMode ? '#FAFAFA' : 'black'}
                className="mb-0"
              >
                {t('latestNews')}
              </Title>
              <Text color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                {t('newsPhrase')}
              </Text>
            </Col>
          </Row>
          <Row className="mt-lg-5">
            {latestNews.map(item => {
              const NewsCard = ({
                color = 'primary',
                title,
                feed,
                date,
                children,
                ...rest
              }) => (
                <a
                  className={styles.newsCard}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={item.url}
                  {...rest}
                >
                  <div className={styles.newsCardContent}>
                    <Title
                      color={userDarkMode ? '#FAFAFA' : 'black'}
                      variant="card"
                      className={styles.cardTitle}
                    >
                      {title}
                    </Title>
                    <Feed className={styles.feedName}>
                      <img
                        width="16"
                        height="16"
                        style={{ marginRight: '5px', borderRadius: '3px' }}
                        src={
                          process.env.WWW_URL +
                          `/assets/img/news/${item.feedImage}`
                        }
                        alt=""
                      />
                      <strong>{feed}</strong>, {date}
                    </Feed>
                  </div>
                </a>
              )

              const d = DateTime.fromISO(item.pubDate, { zone: 'utc' })
              const localDate = d.toLocal().toISO()

              return (
                <Col key={item.id} lg="4" md="6" className="pt-4">
                  <NewsCard
                    style={{
                      backgroundColor: userDarkMode ? '#191B20' : 'light',
                    }}
                    color="primary"
                    title={item.title}
                    date={timeDifference(current, new Date(localDate))}
                    feed={item.feedName}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default LatestNews
